.container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    height: 100vh;
}

/* Common */
.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    cursor: pointer;
    display: block;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-inline: 10px;
    padding-block: 18px;
}

.whiteRow {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-block: 6px;
    padding-inline: 22px;
    padding-block: 18px;
    border-radius: 5px;
}

.descriptionRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -10px;
    margin-left: 60px;
    margin-right: 10px;
    padding-bottom: 18px;
}

.verticalDivider {
    background-color: rgba(17, 127, 132, 0.24);
    width: 1.2px;
    height: 250%;
    margin-left: -10px;
}

.horizontalDivider {
    background-color: rgba(17, 127, 132, 0.24);
    width: 100%;
    height: 0.5px;
}

.modalIndicator {
    background-color: #878686;
    width: 100px;
    height: 3px;
    align-self: center;
    margin-bottom: 12px;
    border-radius: 5px;
}

.whiteTitle {
    margin: 0px;
    margin-bottom: 5px;
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
}

.blackText {
    margin: 0px;
    color: black;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
}

.greyText {
    margin: 0px;
    color: #979797;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
}

.whiteText {
    margin: 0px;
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.lightTealText {
    margin: 0px;
    color: #1cadb4;
    font-size: 13px;
    font-weight: 500;
}

.tealText {
    margin: 0px;
    color: #117f84;
    font-size: 13px;
    font-weight: bold;
}

.regularTealText {
    margin: 0px;
    color: #227c97;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    line-height: 22px;
}

.greenText {
    margin: 0px;
    margin-left: auto;
    padding-block: 7px;
    color: #00bc6b;
    font-size: 15px;
    font-weight: bold;
}

.icon {
    width: 25px;
    height: 20px;
    object-fit: contain;
    margin-inline: 12px;
}

.markerIconIndex {
    width: 12px;
    margin: 0px;
    margin-top: -4px;
    margin-left: -27px;
    margin-right: 17px;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
}

.mapMarkerOrdinal {
    margin-top: -8px;
}

/* Order Menu */
.orderMenu {
    background-color: #167296;
    background-image: url("../../images/place_order/order-menu-background.png");
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    flex: 3;
    min-width: 320px;
    margin-top: 70px;
    padding-block: 30px;
    padding-inline: 40px;
    overflow-y: scroll;
}

.importButton {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding-block: 6px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.pickUpContainer {
    width: 100%;
    background-color: #e7f5f6;
    align-self: center;
    margin-block: 8px;
    border-radius: 10px;
}

.pickUpAddressContainer {
    width: 100%;
    background-color: white;
    align-self: center;
    margin-top: 8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.dropOffContainer {
    width: 100%;
    background-color: white;
    align-self: center;
    margin-bottom: 8px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding-top: 3px;
}

.voucherContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-block: 5px;
    padding-inline: 10px;
    padding-block: 10px;
    background-color: white;
    border-radius: 10px;
}

.totalContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-block: 5px;
    padding-inline: 20px;
    padding-top: 10px;
    padding-bottom: 12px;
    background-color: #e7f5f6;
    border-radius: 10px;
}

.button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-block: 5px;
    padding-block: 15px;
    background-color: #1cadb4;
    border-radius: 10px;
    cursor: pointer;
}

.selectVoucherButton {
    background-color: #1c749a;
    display: inline-block;
    margin-left: auto;
    padding-block: 8px;
    padding-inline: 15px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    border-radius: 7px;
    box-shadow: 0px 3px 6px 0px rgba(34, 124, 151, 0.43);
    cursor: pointer;
}

.pickUpDatepicker {
    width: 100%;
    background-color: #e7f5f6;
    color: black;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    border: 0px;
    cursor: pointer;
    outline: none;
    height: 56px;
}

.dropOffDatepicker {
    width: 100%;
    background-color: white;
    color: black;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    border: 0px;
    cursor: pointer;
}

.textInput {
    display: flex;
    flex: 1;
    background-color: white;
    margin-block: 6px;
    padding-inline: 22px;
    padding-block: 18px;
    color: black;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    text-overflow: ellipsis;
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
}

:global(.react-tel-input .form-control) {
    width: 100% !important;
    padding-left: 50px !important;
}

.autocomplete {
    width: 100%;
    background-color: white;
    margin-right: 8px;
    color: black;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    text-overflow: ellipsis;
    border: 0px;
    cursor: pointer;
    padding: 8px;
    outline-color: #167296;
}

.deliveryFeeContainer {
    background-color: #ffd52e;
    min-width: 60px;
    max-width: 60px;
    margin-left: auto;
    margin-right: 15px;
    padding: 5px;
    border-radius: 5px;
}

/* Map */
.map {
    background-color: white;
    flex: 7;
    margin-top: 70px;
}

.infoWindowContainer {
    width: 250px;
    margin-left: 5px;
    text-align: left;
}

.infoBlackText {
    margin-block: 8px;
    margin-left: 1px;
    font-size: 11.5px;
    font-weight: 500;
}

.infoWhiteText {
    margin-block: 8px;
    margin-left: 1px;
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.infoTealText {
    margin-block: 8px;
    margin-left: 1px;
    color: #117f84;
    font-size: 11.5px;
    font-weight: 500;
}

.infoDeliveryFee {
    background-color: #ffd52e;
    margin-left: auto;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
}

.infoMarkerIconIndex {
    width: 12px;
    position: absolute;
    margin: 0px;
    margin-top: -4px;
    margin-left: 6.3px;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
}

/* Enter Details */
.enterDetailsContainer {
    position: fixed;
    width: 20%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    background-color: #303334;
    align-self: center;
    top: 138px;
    margin-inline: 15px;
    padding-block: 10px;
    padding-inline: 15px;
    border-radius: 10px;
    z-index: 1;
}

.additionalDetailsContainer {
    width: 100%;
    background-color: #e7f5f6;
    align-self: center;
    margin-block: 6px;
    border-radius: 5px;
}

.whiteInput {
    background-color: white;
    padding-block: 5px;
    padding-inline: 8px;
    border: 1px solid #abd4da;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    width: 97%;
    margin-top: 18px;
    align-self: center;
}

/* Modals Common */
.modalButtonContainer {
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    justify-content: center;
    padding-block: 15px;
    border-top: 1px solid #e5e2e2;
}

.modalButton {
    background-image: linear-gradient(to right, #45b0b6, #227c97);
    display: inline-block;
    margin-block: 5px;
    padding-block: 12px;
    padding-inline: 80px;
    border-radius: 10px;
    box-shadow: 0px 4px 5px 0px rgba(34, 124, 151, 0.3);
    cursor: pointer;
}

/* Import Modal */
.importModal {
    position: fixed;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 460px;
    width: 550px;
    z-index: 101;
    padding: 20px;
    border-radius: 10px;
}

.uploadContainer {
    margin-block: 15px;
    padding: 20px;
    padding-top: 25px;
    border: 1.5px dashed #227c97;
    border-radius: 10px;
}

.uploadIcon {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.uploadButton {
    background-image: linear-gradient(to right, #45b0b6, #227c97);
    display: inline-block;
    margin-block: 5px;
    padding-block: 8px;
    padding-inline: 20px;
    align-self: center;
    border-radius: 5px;
    box-shadow: 0px 3px 5px 0px rgba(34, 124, 151, 0.3);
    cursor: pointer;
}

.removeButton {
    background-color: white;
    display: inline-block;
    margin-block: 5px;
    padding-block: 8px;
    padding-inline: 20px;
    align-self: center;
    border: 1px solid #227c97;
    border-radius: 5px;
    cursor: pointer;
}

/* Saved Address Modal */
.savedAddressModal {
    position: fixed;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 450px;
    width: 550px;
    z-index: 101;
    padding: 20px;
    border-radius: 10px;
}

.searchText {
    display: flex;
    flex: 1;
    width: calc(100% - 30px);
    margin-top: 15px;
    margin-bottom: 5px;
    padding-inline: 15px;
    padding-block: 10px;
    color: black;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    cursor: pointer;
}

.addressesContainer {
    display: flex;
    flex-direction: column;
    height: 67%;
    padding-inline: 5px;
    padding-bottom: 10px;
    align-items: center;
    overflow-y: scroll;
}

.addressRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-block: 15px;
}

.locationIcon {
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin-top: 2px;
    margin-left: 5px;
    margin-right: 15px;
}

/* Voucher Modal */
.voucherModal {
    position: fixed;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 500px;
    width: 450px;
    z-index: 101;
    padding: 20px;
    border-radius: 10px;
}

.vouchersContainer {
    display: flex;
    flex-direction: column;
    height: 80%;
    margin-top: 10px;
    padding-inline: 5px;
    padding-bottom: 10px;
    align-items: center;
    overflow-y: scroll;
}

.voucherRow {
    display: flex;
    flex-direction: row;
    width: 96%;
    margin-block: 5px;
    padding: 15px;
    padding-right: 5px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    cursor: pointer;
}

.voucherImg {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.codeContainer {
    background-color: #f6f6f6;
    display: inline-block;
    margin-block: 6.6px;
    padding-block: 5px;
    padding-inline: 10px;
    border: 1px dashed #bfbfbf;
    border-radius: 5px;
}

/* Payment Modal */
.paymentModal {
    position: fixed;
    background-image: linear-gradient(white, #e1faff, #e1faff);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 300px;
    width: 450px;
    z-index: 101;
    padding: 20px;
    border-radius: 10px;
}

.notesToRiderInput {
    background-color: white;
    width: 95%;
    height: 120px;
    margin-top: 8px;
    margin-bottom: 15px;
    padding-inline: 10px;
    padding-block: 8px;
    color: black;
    font-size: 12px;
    text-align: left;
    border: 1px solid #e5e2e2;
    border-radius: 5px;
    cursor: pointer;
}

.payButtonContainer {
    background-color: white;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-block: 15px;
    align-items: center;
    border-top: 1px solid #e5e2e2;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.walletIcon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    padding-inline: 20px;
}

.payButton {
    background-image: linear-gradient(to right, #45b0b6, #227c97);
    display: inline-block;
    margin-block: 5px;
    margin-left: auto;
    margin-right: 20px;
    padding-block: 12px;
    padding-inline: 50px;
    border-radius: 10px;
    box-shadow: 0px 4px 5px 0px rgba(34, 124, 151, 0.3);
    cursor: pointer;
}

/* Top Up Modal */
.topUpModal {
    position: fixed;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 415px;
    width: 450px;
    z-index: 101;
    padding: 20px;
    border-radius: 10px;
}

.muiInputLabel {
    transform: translate(14px, -5px) scale(0.75) !important;
}

.chip {
    width: 100%;
    cursor: pointer !important;
}

.creditCardContainer {
    display: flex;
    height: 20px;
    margin-top: 10px;
    padding: 15px 25px;
    border: 1px solid #227C97;
    border-radius: 10px;
    cursor: pointer;
}

.hstack {
	display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
	align-items: center;
}

.vstack {
	display: flex;
    flex-direction: column;
    flex: 1;
	align-items: stretch;
}

.creditCardIcon {
    height: 19px;
}

.creditCardText {
    color: black;
    font-size: 12px;
}

.creditCardExpText {
    color: #929292;
    font-size: 11px;
}

/* Responsive UI */
@media (max-width: 850px) {
    .markerIconIndex {
        margin-left: -29px;
    }
    
    .enterDetailsContainer {
        width: calc(100% - 30px);
        top: auto;
        bottom: 0px;
        margin-inline: 0px;
        padding-top: 20px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

@media (max-width: 700px) {
    .markerIconIndex {
        margin-left: -28px;
    }

    .savedAddressModal {
        width: 450px;
    }

    .importModal {
        width: 450px;
    }
}

@media (max-width: 600px) {
    .savedAddressModal {
        width: 400px;
    }

    .importModal {
        width: 400px;
    }

    .voucherModal {
        width: 400px;
    }

    .paymentModal {
        width: 400px;
    }

    .topUpModal {
        width: 400px;
        height: 450px;
    }
}

@media (max-width: 550px) {
    .orderMenu {
        min-width: 280px;
        margin-top: 55px;
        padding-inline: 20px;
    }
}

@media (max-width: 500px) {
    .markerIconIndex {
        margin-left: -27px;
    }
    
    .savedAddressModal {
        width: 350px;
    }

    .importModal {
        width: 350px;
    }

    .voucherModal {
        width: 350px;
    }

    .paymentModal {
        width: 350px;
    }

    .topUpModal {
        width: 350px;
    }
}

@media (max-width: 450px) {
    .savedAddressModal {
        width: 320px;
    }

    .importModal {
        width: 320px;
    }

    .voucherModal {
        width: 320px;
    }

    .paymentModal {
        width: 320px;
    }

    .topUpModal {
        width: 320px;
    }
}

@media (max-width: 400px) {
    .orderMenu {
        margin-top: 45px;
    }

    .markerIconIndex {
        margin-left: -26px;
    }

    .savedAddressModal {
        width: 280px;
    }

    .importModal {
        width: 280px;
    }

    .voucherModal {
        width: 280px;
    }

    .paymentModal {
        width: 280px;
    }

    .walletIcon {
        width: 30px;
        height: 30px;
        padding-left: 20px;
        padding-right: 10px;
    }

    .payButton {
        padding-inline: 20px;
    }

    .topUpModal {
        width: 280px;
    }
}

@media (max-width: 360px) {
    .markerIconIndex {
        margin-left: -25px;
    }
    
    .savedAddressModal {
        width: 240px;
    }

    .importModal {
        width: 240px;
    }

    .voucherModal {
        width: 240px;
    }

    .paymentModal {
        width: 240px;
    }

    .topUpModal {
        width: 240px;
    }
}