.mainContainer {
    background-color: white;
    width: 100%;
    position: fixed;
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 10px;
    align-items: center;
    box-shadow: 0px 4px 10px 0px rgba(129, 129, 129, 0.35);
    z-index: 10;
}

.whatsappLogo {
    position: fixed;
    width: 80px;
    height: 80px;
    object-fit: contain;
    bottom: 0;
    right: 0;
    margin: 20px;
    border-radius: 40px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    z-index: 999;
}

.deliveritLogo {
    width: 130px;
    object-fit: contain;
    margin-top: -10px;
    margin-inline: 40px;
    cursor: pointer;
}

.text {
    margin-top: -5px;
    margin-inline: 20px;
    color: #787878;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.selectedText {
    margin-top: -5px;
    margin-inline: 20px;
    color: #227c97;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.tealText {
    margin-inline: 12px;
    color: #227c97;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.blackText {
    margin-block: 10px;
    color: black;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 25px;
    margin-top: -8px;
}

.button {
    background-color: #227c97;
    width: 110px;
    display: inline-flex;
    justify-content: center;
    margin-inline: 10px;
    padding-block: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.buttonText {
    margin: 0;
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.detailsContainer {
    display: flex;
    flex-direction: row;
    margin-top: -10px;
    margin-left: auto;
    margin-right: 15px;
    align-items: center;
}

.icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 10px;
}

/* Dropdown */
.dropdownContent {
    display: none;
    position: absolute;
    background-color: white;
    width: 160px;
    margin-left: -20px;
    padding: 10px 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    z-index: 1;
}

.dropdown:hover .dropdownContent {
    display: block;
}

.dropdownIcon {
    width: 15px;
    height: 15px;
    object-fit: contain;
    margin-right: 10px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.horizontalDivider {
    background-color: #e0e0e0;
    width: 100%;
    height: 1px;
    margin-block: 13px;
}

/* Responsive UI */
@media (max-width: 850px) {
    .whatsappLogo {
        height: 50px;
        width: 50px;
        margin: 20px !important;
    }
}

@media (max-width: 550px) {
    .dropdownContent {
        width: 130px;
        margin-left: -120px;
    }

    .tealText {
        font-size: 13px;
    }

    .icon {
        width: 25px;
        height: 25px;
    }

    .deliveritLogo {
        width: 90px;
        margin-inline: 20px;
    }

    .button {
        width: 80px;
        margin-inline: 5px;
        padding-block: 8px;
    }

    .buttonText {
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .tealText {
        font-size: 12px;
    }

    .buttonsContainer {
        margin-right: 10px;
    }

    .button {
        width: 70px;
    }
}