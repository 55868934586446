.container {
    background-color: white;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
}

.container ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

.container ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

.container ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;    
}

.container li ol > li {
    margin: 0;
}

.container li ol > li:before {
    content: counters(item, ".") " ";
}

.titleBanner {
    background: linear-gradient(to right, #6AC4C5, #387596);
    border-radius: 5px;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    color: #fff !important;
    width: calc(100% - 40px);
    height: 25px;
    padding: 20px;
    margin-bottom: 20px;
}

.title {
    margin: 0;
}