.container {
    background-color: white;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
}

.breadcrumb {
    margin-bottom: 20px !important;
}

.subtitle {
    font-weight: 400;
}

.denseTable {
    margin-bottom: 20px;    
}

.tableHeader {
    color: #06688F !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
}

.tableHeaderNarrow {
    color: #06688F !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
    min-width: 100px;
    max-width: 100px;
    word-wrap: break-word
}

.tableCellNarrow {
    min-width: 100px;
    max-width: 100px;
    word-wrap: break-word
}

.gradientButton {
    background: linear-gradient(to right, #6AC4C5, #387596);
    border: 0;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    color: #fff !important;
    text-transform: none !important;
    height: 46px;
    padding: 0 30px;
    width: 150px;
}

.gradientIconButton {
    background: linear-gradient(to right, #6AC4C5, #387596);
    border: 0;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    color: #fff !important;
    text-transform: none !important;
    height: 35px;
    padding: 0 30px;
    width: 100px;
}

.fileUploadGradientButton {
    background: linear-gradient(to right, #6AC4C5, #387596);
    border: 0;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    border-radius: 5px;
    margin-left: 0;
    margin-right: 0;
    width: 40%;
    padding-left: 0;
    padding-right: 0;
    color: #fff !important;
    text-transform: none !important;
    cursor: pointer;
}

.toolbarButton {
    /* background: linear-gradient(to right, #6AC4C5, #387596); */
    border: 0;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    color: #387596 !important;
    text-transform: none !important;
    height: 48;
    padding: 0 30px;
    width: 150px;
}

.fileUploadContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
}

.fileUploadInput {
    border: 1px solid #ccc;
    height: 45px;
    line-height: 2.5;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 13px;
    padding-top: 3px;
    width: 60%;
}

.fileRemoveButton {
    border-radius: 5px;
    margin-left: -5px;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    border: 0;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    background-color: #f9f9f9;
    color: #177598;
}

.gradientButton:disabled {
    opacity: 0.5;
}

.plainButton {
    background-color: #177598 !important;
    border: 0;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    color: #fff !important;
    text-transform: none !important;
    padding: 0 30px;
    min-width: 180px;
}

.removeVoucherIcon {
    position: absolute;
    right: 20px;
    top: 24px;
    font-size: 20px !important;
    cursor: pointer;
}

.modal {
    display: 'flex';
    padding: 10px;
    align-items: 'center';
    justify-content: 'center';
}

.modalContainer {
    width: 80%;
    max-width: 800px;
    max-height: 80%;
    overflow: scroll;
    padding: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modalContainer:focus {
    outline: 0;
}

.closeButton {
    float: right;
    cursor: pointer;
}

.backButton {
    cursor: pointer;
    color: #387596;
}

.errorMessage {
    color: red;
    font-size: 12px;
}

.textInputContainer {
    margin-bottom: 12px;
    width: 100%;
}

.textInput {
    border: none;
    width: calc(100% - 20px);
    height: 40px;
    padding: 0 10px;
    border-radius: 3px;
    background: transparent;
}
  
.textInput:focus {
    outline: none !important;
    border: 2px solid #387596;
    box-shadow: 1px 2px 5px #719ECE;
}

.submitButtonContainer {
    margin-top: 30px;
    text-align: right;
}

.rateBigFont {
    font-size: 18px;
}

.rateSmallFont {
    font-size: 12px;
}

.textArea {
    border-color: #dfdfdf;
    border-radius: 3px;
    width: 100%;
    min-height: 100px;
}

.labelParagraph {
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 5px;
}

.labelSmallParagraph {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 5px;
}

.bankAccParagraph {
    font-size: 12px;
    font-style: italic;
    margin: 0;
}

.labelIcon {
    height: 18px;
    float: left;
    margin-left: 1px;
    margin-right: 10px;
    padding-top: 2px;
}

.labelSmallIcon {
    height: 12px;
    float: left;
    margin-left: 1px;
    margin-right: 5px;
}

.infoIcon {
    height: 18px;
    margin-left: 20px;
    margin-right: 10px;
}

.discountTitle {
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 0;
}

.discountText {
    color: #00BC6B;
    font-weight: bold;
    font-size: 15px;
    margin-top: 0px;
}

.securePaymentContainer {
    color: gray;
    font-size: 12px;
    position: absolute;
    top: 3px;
    left: 130px;
    margin: 0
}

.selectPaymentMethodContainer {
    display: flex;
    align-items: flex-start;
}

.paymentMethodTitle {
    margin-top: 11px;
    margin-bottom: 0px;
    font-weight: 500;
}

.creditCardIcon {
    height: 35px;
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
}

.addCreditCardContainer {
    margin-top: 15px;
    margin-bottom: 20px;
    align-items: center;
    display: flex;
}

.downloadLink {
    font-size: 15px;
    text-decoration: none;
    color: #177598;
    padding-left: 20px;
    padding-bottom: 0;
    padding-top: 20px;
}

.downloadLinkIcon {
    float: left;
    margin-right: 5px;
    font-size: 18px !important;
}