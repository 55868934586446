.modal {
  display: 'flex';
  padding: 10px;
  align-items: 'center';
  justify-content: 'center';
}

.modalContainer {
  width: 80%;
  max-width: 500px;
  max-height: 80%;
  overflow: scroll;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modalContainer:focus {
  outline: 0;
}

.closeButton {
  float: right;
  cursor: pointer;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 12px;
  color: #387596;
  line-height: 20px;
}

.cardContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: white;
  align-self: center;
  padding: 12px 14px;
  padding-right: 0;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 6px;
}

.cardIcon {
  height: 80px;
  width: 80px;
  margin-right: 11px;
  border-radius: 3px;
}

.cardHeader {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 3px;
}

.voucherContent {
  flex-direction: column;
  display: flex;
  flex: 1;
}
  
.voucherCodeContainer {
  display: flex;
  align-self: baseline;
  background-color: #f6f6f6;
  border-color: #bfbfbf;
  border-width: 1px;
  border-style: dashed;
  border-radius: 5px;
  padding: 8px 12px;
  margin-bottom: 7px;
}

.voucherCodeText {
  font-size: 15px;
  font-weight: bold;
  color: #387596;
  margin: 0;
}

.voucherExpText {
  font-size: 10px;
  color: #bfbfbf;
  margin: 0;
}

.tncText {
  font-size: 10px;
  color: #387596;
  position: absolute;
  bottom: 0;
  right: 10px;
  cursor: pointer;
}

.backButton {
  cursor: pointer;
  color: #387596;
}

.errorMessage {
  color: red;
  font-size: 12px;
}

.gradientButton {
  background: linear-gradient(to right, #6AC4C5, #387596);
  border: 0;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
  color: #fff !important;
  text-transform: none !important;
  height: 48px;
  padding: 0 30px;
  width: 150px;
}

.submitButtonContainer {
  margin-top: 30px;
  text-align: center;
}