body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker-wrapper {
  width: 100%;
}

.pac-container {
  z-index: 10000;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  padding-top: 10px !important;
  max-width: calc(100% - 25px);
}

.MuiChip-colorPrimary {
  background-color: #227C97 !important;
}

.MuiChip-outlinedPrimary {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: transparent !important;
  color: black !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  color: rgba(34, 124, 151, 0.23) !important;
  border-color: rgba(34, 124, 151, 0.23) !important;
}