.container {
    text-align: left;
    width: 1140px;
}

.invoiceContainer {
    padding: 0 5%;
}

.title {
    font-size: 22px;
    font-weight: 500;
}

.subtitle {
    font-size: 16px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.description {
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 5px;
}

.floatRight {
    float: right;
    text-align: right;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.logoContainer {
    background-color: #fff;
}

.logo {
    margin-top: 20px;
    height: 80px;
}

.shopIcon {
    float: right;
    height: 60px;
    margin-top: 30px;
}

.invoiceNumberContainer {
    justify-content: flex-end;
    text-align: right;
}

.denseTable {
    margin-bottom: 20px;
}

.tableHeader {
    color: #06688F !important;
    font-weight: bold !important;
    text-align: left;
}

.tableLink {
    color: #06688F !important;
    font-weight: bold !important;
    cursor: pointer;
}

.tableHeaderNarrow {
    color: #06688F !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
    min-width: 100px;
    max-width: 100px;
    word-wrap: break-word
}

.tableCellNarrow {
    min-width: 100px;
    max-width: 100px;
    word-wrap: break-word
}

.buttonContainer {
    margin-top: 40px;
    text-align: center;
    height: 50px;
}

.gradientButton {
    background: linear-gradient(to right, #6AC4C5, #387596);
    border: 0;
    border-radius: 5px;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    color: #fff !important;
    text-transform: none !important;
    height: 40px;
    padding: 0 30px;
    min-width: 150px !important;
}

.dueDateText {
    color: #d42a5c;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 20px;
}

.paidText {
    color: #0bce6d;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 20px;
}

.paymentMethodIcon {
    height: 25px;
    float: left;
    margin-right: 10px;
}

.paymentMethodText {
    color: #448ca9;
    font-size: 14px;
}

.paymentInstructionText {
    font-size: 14px;
    margin: 0;
    margin-left: 20px;
    margin-top: 5px;
}

.faqText {
    font-size: 10px;
    margin: 0;
    margin-top: 5px;
    text-align: center;
}