.mainContainer {
    padding: 30px 40px 0px 40px;
}

.title {
    margin: 0px;
    color: black;
    font-size: 25px;
    font-weight: 500;
    text-align: left;
    padding-top: 70px;
}

.row {
    display: flex;
    align-items: center;
}

.blackText {
    margin: 0px;
    color: black;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 22px;
}

.whiteText {
    margin: 0px;
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.tealText {
    margin: 0px;
    color: #227c97;
    font-size: 12px;
    font-weight: bold;
}

.lightTealText {
    margin: 0px;
    color: #1cadb4;
    font-size: 12px;
    font-weight: bold;
}

.redText {
    margin: 0px;
    color: #de4d65;
    font-size: 12px;
    font-weight: bold;
}

.downloadIcon {
    width: 20px;
    height: 20px;
    margin-left: auto;
    padding-top: 70px;
    padding-right: 5px;
    object-fit: contain;
    cursor: pointer;
}

.tableContainer {
    padding-block: 20px;
    padding-bottom: 40px;
}

.denseTable {
    margin-bottom: 20px;
}

.tableHeader {
    min-width: 240px;
    max-width: 240px;
    padding-block: 15px !important;
    color: #227c97 !important;
    font-weight: bold !important;
}

.tableHeaderNarrow {
    min-width: 80px;
    max-width: 80px;
    padding-block: 15px !important;
    color: #227c97 !important;
    font-weight: bold !important;
}

.tableHeaderExtraNarrow {
    min-width: 30px;
    max-width: 30px;
    padding-block: 15px !important;
    color: #227c97 !important;
    font-weight: bold !important;
}

.tableCell {
    min-width: 240px;
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableCellNarrow {
    min-width: 80px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableCellExtraNarrow {
    min-width: 30px;
    max-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.statusContainer {
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
}

.tealButton {
    display: inline-block;
    margin-right: 10px;
    padding: 7px 15px;
    border-radius: 20px;
    box-shadow: 0px 3px 6px 0px rgba(34, 124, 151, 0.47);
    cursor: pointer;
}

.lightTealButton {
    display: inline-block;
    padding: 7px 15px;
    border: 1px solid #1cadb4;
    border-radius: 20px;
    cursor: pointer;
}

.redButton {
    display: inline-block;
    padding: 7px 15px;
    border: 1px solid #de4d65;
    border-radius: 20px;
    cursor: pointer;
}

/* Remove Order Modal */
.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    cursor: pointer;
    display: block;
}

.removeOrderModal {
    position: fixed;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    height: 170px;
    width: 300px;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    z-index: 101;
}

.buttonsContainer {
    display: flex;
    margin-left: auto;
    margin-top: 20px;
    flex-direction: row;
    align-items: center;
}

.whiteButton {
    display: flex;
    align-items: center;
    margin-inline: 4px;
    padding: 6px 8px;
    justify-content: center;
    border: 1px solid #227c97;
    border-radius: 5px;
    cursor: pointer;
}

.modalTealButton {
    background-color: #227c97;
    display: flex;
    align-items: center;
    margin-inline: 4px;
    padding: 6px 15px;
    justify-content: center;
    border: 1px solid #227c97;
    border-radius: 5px;
    cursor: pointer;
}

/* Download Report Modal */
.downloadReportModal {
    position: fixed;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    height: 170px;
    width: 400px;
    padding: 25px 30px;
    border-radius: 10px;
    z-index: 101;
}

.textInput {
    border: 1px solid #bfbfbf;
    width: calc(100% - 20px);
    height: 40px;
    padding: 0 10px;
    border-radius: 3px;
    background: transparent;
}

.textInput:focus {
    outline: none !important;
    border: 2px solid #387596;
    box-shadow: 1px 2px 5px #719ECE;
}

/* Responsive UI */
.lightBlackText {
    margin: 0px;
    color: black;
    font-size: 13px;
    font-weight: 500;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.greyText {
    margin: 3px 0px;
    color: #606060;
    font-size: 12px;
    font-weight: 500;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.responsiveContainer {
    margin-top: -10px;
    padding-block: 15px;
}

.responsiveOrderCard {
    margin-block: 12px;
    padding-top: 10px;
    text-align: left;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.12);
}

.greyRow {
    background-color: rgba(224, 224, 224, 0.36);
    display: flex;
    align-items: center;
    padding-block: 8px;
    padding-inline: 12px;
}

.icon {
    width: 10px;
    height: 10px;
    object-fit: contain;
    margin-right: 8px;
}