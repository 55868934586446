.container {
  background-color: white;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Common */
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  margin: 0;
  margin-bottom: 10px;
  color: #00cec2;
  font-size: 30px;
  font-weight: bold;
}

.button {
  background-color: #108f97;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding-block: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.buttonText {
  margin: 0;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

/* Top Banner */
.topBannerContainer {
  width: 100%;
  background-image: url("../../images/landing/top-banner-background.png");
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 100px;
}

.copywriteContainer {
  margin-inline: 50px;
  margin-top: 20px;
  text-align: left;
}

.header {
  margin: 0;
  margin-left: 50px;
  color: #2e4861;
  font-size: 45px;
  font-weight: 300;
}

.description {
  margin: 0;
  margin-top: 10px;
  margin-left: 50px;
  color: #4d4d4d;
  font-size: 16px;
}

.image {
  width: 40vw;
  object-fit: contain;
  margin-top: 50px;
  margin-right: 70px;
}

/* Services Banner */
.servicesBannerContainer {
  width: 100%;
  background-image: url("../../images/landing/services-banner-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 75px;
  padding-bottom: 80px;
}

.servicesContainer {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  justify-content: center;
  flex-wrap: wrap;
}

.serviceContainer {
  background-color: white;
  width: 15vw;
  min-width: 250px;
  margin-inline: 10px;
  padding-block: 35px;
  padding-inline: 30px;
  border-radius: 15px;
}

.serviceIcon {
  width: 55px;
  height: 55px;
  object-fit: contain;
  margin-top: 10px;
  margin-bottom: 15px;
}

.serviceTitle {
  margin: 0;
  padding-block: 5px;
  color: #464558;
  font-size: 17px;
  font-weight: bold;
}

.serviceRow {
  display: flex;
  flex-direction: row;
}

.serviceDescription {
  margin: 0;
  padding-block: 5px;
  color: #7b7a8b;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

/* GIF Banner */
.gifBannerContainer {
  width: 100%;
  padding-block: 20px;
  flex-wrap: wrap;
}

.gifImage {
  width: 30vw;
  height: 30vw;
  margin-inline: 10px;
  object-fit: contain;
}

/* Client Banner */
.clientBannerContainer {
  width: 100%;
  padding-block: 50px;
}

.clientLogo {
  width: 160px;
  height: 100px;
  object-fit: contain;
  margin-block: 15px;
  margin-inline: 10px;
  cursor: pointer;
}

/* Testimonial Banner */
.testimonialBannerContainer {
  width: 100%;
  background: linear-gradient(to right, rgb(255, 244, 226), white, white, white, rgba(20, 161, 192, 0.35));
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
}

.openInvertedCommaImage {
  width: 90px;
  object-fit: contain;
}

.testimonialRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
  margin-block: 30px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.testimonialContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  height: 150px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-inline: 30px;
  padding-block: 30px;
  padding-inline: 35px;
  border-radius: 15px;
  text-align: left;
  box-shadow: 0px 3px 6px 0px #bfbfbf;
}

.testimonialReview {
  margin: 0;
  color: #7b7a8b;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}

.testimonialName {
  margin: 0;
  color: black;
  font-size: 15px;
  font-weight: bold;
}

.starsIcon {
  width: 100px;
  object-fit: contain;
}

/* Be Our Driver Banner */
.beDriverBannerContainer {
  width: 100%;
  background-image: url("../../images/landing/bottom-banner-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 150px;
  padding-bottom: 100px;
}

.beOurDriverText {
  margin: 0;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.contactUsRow {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
}

.deliverEverywhereGif {
  width: 40vw;
  height: 30vw;
  object-fit: contain;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.contactIcon {
  width: 16px;
  object-fit: contain;
  margin-right: 15px;
}

.socmedLogo {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 20px;
  cursor: pointer;
}

.whatsappLogo {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 12px;
}

/* Bottom Banner */
.bottomBannerContainer {
  width: 100%;
  background-color: #2f2e41;
  display: flex;
  flex-direction: row;
  padding-block: 30px;
  justify-content: center;
  align-items: center;
}

.deliveritContainer {
  display: flex;
  flex-direction: column;
  margin-inline: 50px;
}

.deliveritLogo {
  width: 180px;
  object-fit: contain;
}

.downloadContainer {
  display: flex;
  flex-direction: row;
}

.storeLogo {
  height: 70px;
  object-fit: contain;
  margin-inline: 20px;
  cursor: pointer;
}

/* Responsive UI */
@media (max-width: 1000px) {
  /* Top Banner */
  .header {
    font-size: 38px;
  }
}

@media (max-width: 900px) {
  /* Common */
  .title {
    font-size: 28px;
  }

  .buttonText {
    font-size: 14px;
  }

  /* Top Banner */
  .header {
    font-size: 34px;
  }

  /* Services Banner */
  .serviceContainer {
    min-width: 200px;
  }

  .serviceTitle {
    font-size: 15px;
  }

  .serviceDescription {
    font-size: 12px;
  }

  /* Client Banner */
  .clientLogo {
    width: 140px;
    height: 80px;
  }

  /* Testimonial Banner */
  .testimonialReview {
    font-size: 13px;
  }

  /* Bottom Banner */
  .deliveritLogo {
    width: 150px;
  }

  .storeLogo {
    height: 50px;
  }
}

@media (max-width: 850px) {
  /* Top Banner */
  .copywriteContainer {
    margin-inline: 30px;
  }

  .image {
    margin-right: 50px;
  }

  /* Services Banner */
  .serviceContainer {
    min-width: 150px;
  }
}

@media (max-width: 750px) {
  /* Top Banner */
  .copywriteContainer {
    margin-inline: 40px;
  }

  .image {
    margin-right: 30px;
  }

  .header {
    font-size: 30px;
  }

  .description {
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  /* Common */
  .title {
    font-size: 25px;
  }

  /* Top Banner */
  .topBannerContainer {
    padding-block: 80px;
  }

  .copywriteContainer {
    margin-left: 20px;
    margin-top: 50px;
  }

  .header {
    font-size: 28px;
  }

  /* Services Banner */
  .servicesBannerContainer {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .servicesContainer {
    margin-top: 10px;
  }

  .serviceContainer {
    min-width: 300px;
    margin-block: 10px;
  }

  .gifImage {
    width: 60vw;
    height: 60vw;
    margin-block: 10px;
  }

  /* Testimonial Banner */
  .testimonialContainer {
    min-width: 250px;
    margin-inline: 10px;
  }

  /* Be Our Driver Banner */
  .contactUsRow {
    margin-top: 10px;
    flex-direction: column;
  }

  .deliverEverywhereGif {
    width: 60vw;
    height: 50vw;
  }

  .contactContainer {
    margin-left: 0px;
  }

  /* Bottom Banner */
  .deliveritContainer {
    margin-right: 30px;
  }

  .storeLogo {
    margin-left: 0px;
    margin-right: 20px;
  }
}

@media (max-width: 650px) {
  /* Common */
  .title {
    font-size: 22px;
  }

  /* Top Banner */
  .copywriteContainer {
    margin-right: 20px;
    margin-top: 20px;
  }

  .header {
    font-size: 25px;
  }

  .description {
    font-size: 12px;
  }

  /* Be Our Driver Banner */
  .beOurDriverText {
    font-size: 15px;
  } 
}

@media (max-width: 600px) {
  /* Top Banner */
  .topBannerContainer {
    padding-bottom: 50px;
  }

  .copywriteContainer {
    margin-left: 10px;
  }

  /* Services Banner */
  .serviceIcon {
    width: 45px;
    height: 45px;
  }

  /* Bottom Banner */
  .deliveritContainer {
    margin-left: 30px;
  }

  .deliveritLogo {
    width: 120px;
  }

  .storeLogo {
    height: 35px;
  }
}

@media (max-width: 525px) {
  /* Common */
  .title {
    font-size: 20px;
  }

  /* Top Banner */
  .header {
    font-size: 19px;
  }

  .copywriteContainer {
    margin-left: 0px;
  }

  /* Testimonial Banner */
  .openInvertedCommaImage {
    width: 60px;
  }

  /* Bottom Banner */
  .deliveritContainer {
    margin-left: 50px;
  }

  .downloadContainer {
    flex-direction: column;
    margin-right: 20px;
  }

  .storeLogo {
    width: 160px;
    height: 60px;
    object-fit: contain;
    margin-block: 5px;
  }
}

@media (max-width: 450px) {
  /* Common */
  .title {
    font-size: 16px;
  }

  .buttonText {
    font-size: 12px;
  }

  /* Top Banner */
  .copywriteContainer {
    margin-top: 40px;
  }

  .header {
    font-size: 15px;
  }

  .description {
    font-size: 11px;
  }

  .image {
    width: 38vw;
  }

  /* Services Banner */
  .servicesBannerContainer {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .serviceIcon {
    width: 40px;
    height: 40px;
  }

  .serviceTitle {
    font-size: 13px;
  }

  .serviceDescription {
    font-size: 11px;
  }

  /* Client Banner */
  .clientLogo {
    width: 80px;
    height: 80px;
  }

  /* Testimonial Banner */
  .testimonialContainer {
    min-width: 200px;
  }

  .testimonialReview {
    font-size: 12px;
  }

  .testimonialName {
    font-size: 13px;
  }

  /* Bottom Banner */
  .storeLogo {
    width: 130px;
    height: 50px;
  }
}

@media (max-width: 400px) {
  /* Common */
  .button {
    padding-block: 10px;
  }

  /* Services Banner */
  .serviceContainer {
    min-width: 250px;
  }

  /* Be Our Driver Banner */
  .contactContainer {
    margin-left: 10px;
  }
}

@media (max-width: 350px) {
  /* Services Banner */
  .serviceContainer {
    min-width: 200px;
  }

  /* Be Our Driver Banner */
  .beOurDriverText {
    font-size: 13px;
  } 

  /* Bottom Banner */
  .storeLogo {
    width: 100px;
    height: 40px;
  }
}