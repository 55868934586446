.container {
  background: linear-gradient(#45B0B6, #227C97);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.cardContainer {
  border-radius: 20px;
  display: flex;
  height: 400px;
  min-width: 850px;
  max-width: 100%;
  background: hsl(0, 0%, 100%);
  margin-top: 20px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  min-height: 300px;
}

.loginModal {
  position: fixed;
  display: flex;
  background: hsl(0, 0%, 100%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: 450px;
  z-index: 101;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  cursor: pointer;
  display: block;
}

.leftContainer {
  flex: 1.2;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: initial;
  width: initial;
  justify-content: center;
  align-items: center;
  background-image: url('../../images/login-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.rightContainer {
  flex: 1.8;
  height: initial;
  width: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.logo {
  margin-top: 80px;
  height: 65px;
}

.errorMessage {
  color: red;
  font-size: 12px;
}

.buttonContainer {
  text-align: center;
}

.gradientButton {
  background: linear-gradient(to left, #45B0B6, #227C97);
  border: 0;
  box-shadow: 0px 3px 6px 0px rgba(34, 124, 151, 0.5);
  color: #fff !important;
  text-transform: none !important;
  height: 37px;
  padding: 0 30px;
  width: 150px;
}

.gradientButton:disabled {
  opacity: 0.5;
}

.textInputContainer {
  position: relative;
  margin-bottom: 12px;
  border: solid 1px #227C97;
  width: calc(70% - 50px);
  height: 40px;
  padding: 0 10px;
  padding-left: 40px;
  border-radius: 8px;
  background: #F3FAFC;
}

.textInput {
  width: calc(100% - 20px);
  height: 40px;
  padding: 0 10px;
  padding-left: 20px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: transparent;
  border: none;
}

.textInputIconContainer {
  position: absolute;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: rgba(34, 124, 151, 0.11);
  display: flex;
  align-items: center;
  justify-content: center;
}

.textInputIcon {
  width: auto;
  height: 20px;
}

.textInput:focus {
  outline: none !important;
}

.forgotPassword {
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 100px;
  align-self: flex-start;
  margin-left: 15%;
}

.poweredBy {
  color: rgb(243, 250, 252);
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
}

.poweredByLink {
  color: rgb(243, 250, 252);
  text-decoration: none;
}

/* Responsive UI */
@media (max-width: 960px) {
  .rightContainer {
    width: 100%;
  }

  .cardContainer {
    width: 90%;
    min-width: 0;
    grid-template-columns: auto;
    margin-bottom: 30%;
  }

  .formContainer {
    padding: 20px;
  }
}

@media (max-width: 900px) {
  .loginModal {
    width: 700px;
  }
}

@media (max-width: 820px) {
  .loginModal {
    width: 550px;
  }
}

@media (max-width: 660px) {
  .loginModal {
    width: 450px;
  }
}

@media (max-width: 550px) {
  .loginModal {
    width: 350px;
  }
}

@media (max-width: 450px) {
  .loginModal {
    width: 280px;
  }
}

@media (max-width: 350px) {
  .loginModal {
    width: 250px;
  }
}