.container {
    background-color: white;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
}

.subtitle {
    font-weight: 400;
}

.tableContainer {
    margin-top: 70px;
    padding-top: 20px;
    padding-inline: 20px;
}

.denseTable {
    margin-bottom: 20px;
}

.tableHeader {
    color: #06688F !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
}

.tableLink {
    color: #06688F !important;
    font-weight: bold !important;
    cursor: pointer;
}

.tableHeaderNarrow {
    color: #06688F !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
    min-width: 100px;
    max-width: 100px;
    word-wrap: break-word
}

.tableHeaderExtraNarrow {
    color: #06688F !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
    min-width: 50px;
    max-width: 50px;
    word-wrap: break-word
}

.tableCellNarrow {
    min-width: 100px;
    max-width: 100px;
    word-wrap: break-word
}

.tableCellExtraNarrow {
    min-width: 50px;
    max-width: 50px;
    word-wrap: break-word
}

.gradientButton {
    background: linear-gradient(to right, #6AC4C5, #387596);
    border: 0;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    color: #fff !important;
    text-transform: none !important;
    height: 48;
    padding: 0 30px;
    min-width: 150px !important;
}

.gradientButton:disabled {
    opacity: 0.5;
}

.modal {
    display: 'flex';
    padding: 10px;
    align-items: 'center';
    justify-content: 'center';
}

.modalContainer {
    width: 80%;
    max-width: 800px;
    max-height: 80%;
    min-height: 250px;
    overflow: scroll;
    padding: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modalContainer:focus {
    outline: 0;
}

.closeButton {
    float: right;
    cursor: pointer;
}

.backButton {
    cursor: pointer;
    color: #387596;
}

.errorMessage {
    color: red;
    font-size: 12px;
}

.textArea {
    border: none;
    border-radius: 3px;
    width: 100%;
    min-height: 50px;
}

.textInputContainer {
    margin-bottom: 12px;
    width: 100%;
}

.textInput {
    border: none;
    width: calc(100% - 20px);
    height: 40px;
    padding: 0 10px;
    border-radius: 3px;
    background: transparent;
}
  
.textInput:focus {
    outline: none !important;
    border: 2px solid #387596;
    box-shadow: 1px 2px 5px #719ECE;
}

.submitButtonContainer {
    margin-top: 30px;
    text-align: right;
}

.vehicleCardContainer {
    margin-bottom: 10px;
    min-height: 87px;
    cursor: pointer;
}

.vehicleCardContentContainer {
    padding: 0px 20px;
}

.selectedVehicleCardContentContainer {
    background-color: #66BBBA;
    padding: 0px 20px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.vehicleCardTitle {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0;
}

.vehicleCardText {
    font-size: 12px;
}

.vehicleIconContainer {
    background-color: #333131;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    min-height: 87px;
}

.vehicleIcon {
    height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}