.mainContainer {
    padding: 30px 40px 0px 40px;
}

.hContainer {
    display: flex;
    flex-direction: row;
    min-height: 82vh;
    height: 100%;
    overflow-y: scroll;
}

/* Details */
.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title {
    margin: 0px;
    color: black;
    font-size: 23px;
    font-weight: 500;
    text-align: left;
    padding-top: 70px;
}

.blackText {
    margin: 0px;
    color: black;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    line-height: 22px;
}

.whiteText {
    margin: 0px;
    color: white;
    font-size: 10px;
    font-weight: 500;
    text-align: left;
    line-height: 22px;
}

.tealText {
    margin: 0px;
    color: #227c97;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    line-height: 22px;
}

.regularTealText {
    margin: 0px;
    color: #227c97;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    line-height: 22px;
}

.greyText {
    margin: 0px;
    color: #979797;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    line-height: 22px;
}

.verticalLine {
    background-color: #d4cbcb;
    width: 1.7px;
    height: 100%;
    margin-top: -3px;
    margin-left: 5.5px;
}

.detailsContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 15px 2px 30px 5px;
    padding-top: 20px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.detailsSubcontainer {
    flex: 8;
    padding-bottom: 30px;
    padding-inline: 25px;
}

.locationContainer {
    display: flex;
    margin-top: 20px;
}

.icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.markerIconIndex {
    width: 12px;
    position: absolute;
    top: 4px;
    left: 3.5px;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
}

.deliveryFeeContainer {
    background-color: #ffd52e;
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 3px;
    padding: 0px 5px;
    border-radius: 3px;
}

.trackingContainer {
    background-color: #f6fdfe;
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 3px;
    padding: 0px 8px;
    border: 1px solid #9ad5da;
    border-radius: 3px;
}

.failedContainer {
    background-color: #fff5f3;
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 3px;
    padding: 0px 8px;
    border: 1px solid #ffd9d4;
    border-radius: 3px;
}

.trackingButton {
    background-color: #1e9299;
    display: inline-block;
    margin-bottom: 4px;
    padding: 0px 8px;
    border: 1px solid #1e9299;
    border-radius: 3px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    cursor: pointer;
}

.disabledTrackingButton {
    background-color: #d4d4d4;
    display: inline-block;
    margin-top: 3px;
    margin-bottom: 4px;
    padding: 0px 8px;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
}

.bottomContainer {
    background-image: linear-gradient(to right, rgba(13, 215, 225, 0.2), rgba(21, 181, 197, 0.12), rgba(13, 215, 225, 0));
    display: flex;
    flex: 2;
}

.totalContainer {
    margin-left: auto;
    padding-left: 10px;
    padding-right: 30px;
    padding-block: 20px;
}

.deliveritLogo {
    width: 100px;
    object-fit: contain;
    margin-top: auto;
}

/* Map */
.map {
    background-color: white;
    flex: 1;
    margin: 15px 0px 30px 20px;
}

.mapMarkerOrdinal {
    margin-top: -8px;
}