.container {
    background-color: white;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
}

.subtitle {
    font-weight: 400;
    font-size: 22px;
    color: #227C97;
    margin-bottom: 11px;
    margin-top: 11px;
}

.denseTable {
    margin-top: 20px;
    margin-bottom: 20px;
}

.tableHeader {
    color: #06688F !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
}

.tableLink {
    color: #06688F !important;
    font-weight: bold !important;
    cursor: pointer;
    text-decoration: none;
}

.tableHeaderNarrow {
    color: #06688F !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
    min-width: 100px;
    max-width: 100px;
    word-wrap: break-word
}

.tableCellNarrow {
    min-width: 100px;
    max-width: 100px;
    word-wrap: break-word
}

.gradientButton {
    background: linear-gradient(to right, #45B0B6, #227C97);
    border: 0;
    border-radius: 8px !important;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    color: #fff !important;
    text-transform: none !important;
    height: 48px;
    padding: 0 30px;
    min-width: 150px !important;
}

.gradientButton:disabled {
    opacity: 0.5;
}

.modal {
    display: 'flex';
    padding: 10px;
    align-items: 'center';
    justify-content: 'center';
}

.modalContainer {
    width: 80%;
    max-width: 500px;
    max-height: 80%;
    border-radius: 18px !important;
    overflow: scroll;
    padding: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modalContainer:focus {
    outline: 0;
}

.closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: #BFBFBF;
}

.errorMessage {
    color: red;
    font-size: 12px;
}

.textArea {
    border: none;
    border-radius: 3px;
    width: 100%;
    min-height: 50px;
}

.textInputContainer {
    margin-bottom: 12px;
    width: 100%;
}

.textInput {
    border: none;
    width: calc(100% - 20px);
    height: 40px;
    padding: 0 20px;
    border-radius: 3px;
    background: transparent;
}
  
.textInput:focus {
    outline: none !important;
}

.submitButtonContainer {
    margin-top: 30px;
}

.mb10 {
    margin-bottom: 10px;
}

.f18 {
    font-size: 18px;
}

.filterContainer {
    border-radius: 10px !important;
    padding: 22px 5%;
    height: 80%;
}

.filterInputContainer {
    border-radius: 25px !important;
    height: 40px;
    width: 220px;
    border: 1px solid #BFBFBF;
    position: relative;
}

.balanceContainer {
    background: linear-gradient(to top, #227C97, #45B0B6);
    border-radius: 10px !important;
    padding: 22px 10%;
    height: 80%;
    color: white !important;
    display: flex;
    justify-content: center;
}

.topUpButton {
    background: linear-gradient(to top, #2F3944, #404C59);
    color: white !important;
    margin-left: 10% !important;
    text-transform: none;
    padding: 12px 20px !important;
}

.topUpIcon {
    width: 20px;
    height: 18px;
    margin-right: 11px;
}

.inputIcon {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.hstack {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
    flex: 1;
}

.vstack {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-direction: column;
    flex: 1;
}

.chip {
    width: 100%;
    cursor: pointer !important;
}

.muiInputLabel {
    transform: translate(14px, -5px) scale(0.75) !important;
}

.paymentMethodContainer {
    cursor: pointer;
    display: flex;
    border: 1px solid #227C97;
    padding: 15px 25px;
    border-radius: 10px;
    margin-top: 20px;
    height: 20px;
}

.creditCardIcon {
    height: 19px;
    margin-right: 12px;
}

.creditCardText {
    color: black;
    font-size: 14px;
}

.creditCardExpText {
    font-size: 12px;
    color: #929292;
}

.buttonIcon {
    width: 20px;
    height: 20px;
}

.buttonIconText {
    flex: 1;
    margin-left: 20px;
}

.greenFont {
    color: #04BB6C;
}

.ml15p {
    margin-left: 15%;
}

.hline {
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    margin-top: 50px;
    margin-bottom: 50px;
}