.mainContainer {
    padding: 30px 35px 0px 35px;
}

.hContainer {
    display: flex;
    flex-direction: row;
    min-height: 82vh;
    height: 100%;
    overflow-y: scroll;
    flex-wrap: wrap;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.horizontalDivider {
    background-color: #e0e0e0;
    width: 100%;
    height: 1px;
    margin-block: 10px;
}

.smallWhiteText {
    margin: 0px;
    color: white;
    font-size: 11px;
    line-height: 20px;
}

.largeWhiteText {
    margin: 0px;
    color: white;
    font-size: 22px;
    font-weight: bold;
}

.smallBlackText {
    margin: 0px;
    color: black;
    font-size: 11px;
    font-weight: 500;
}

.largeBlackText {
    margin: 0px;
    color: black;
    font-size: 13px;
    font-weight: bold;
}

.greyText {
    margin: 0px;
    margin-top: 5px;
    color: #979797;
    font-size: 11px;
    font-weight: 500;
}

.leftContainer {
    flex: 4.5;
    margin: 15px 5px 30px 5px;
}

.rightContainer {
    flex: 5.5;
    margin: 15px 5px 30px 5px;
}

/* Filter */
.filterRow {
    display: flex;
    padding-top: 70px;
    margin-left: 5px;
}

.datepickerContainer {
    display: flex;
    flex-direction: row;
    margin-left: 5px;
    text-align: left;
}

.datepicker {
    width: 180px;
    background-color: #e0e0e0;
    padding: 5px 10px 5px 10px;
    color: #606060;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    border: 0px;
    border-radius: 3px;
    cursor: pointer;
}

/* Order Summary Cards */
.orderSummaryCard {
    display: flex;
    flex: 1;
    flex-direction: row;
    min-width: 120px;
    margin-inline: 5px;
    margin-bottom: 10px;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.16);
}

.orderSummaryIcon {
    width: 25px;
    height: 25px;
    object-fit: contain;
}

/* Order List Table */
.orderListTable {
    max-height: 547px;
    min-width: 340px;
    margin-top: 10px;
    margin-inline: 5px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    overflow-y: scroll;
}

.tableHeader {
    background: linear-gradient(to right, #1e9299, #227c97);
}

.tableCellTitle {
    min-width: 30px;
    max-width: 30px;
    padding: 12px !important;
    color: white !important;
    font-size: 12px !important;
    line-height: 15px !important;
}

.tableCellContent {
    min-width: 30px;
    max-width: 30px;
    padding: 12px !important;
    color: black !important;
    font-size: 12px !important;
    line-height: 15px !important;
}

.tableCellNarrow {
    min-width: 10px;
    max-width: 10px;
    padding-block: 10px !important;
    padding-left: 5px !important;
    padding-right: 0px !important;
    color: #929292 !important;
    font-size: 12px !important;
    line-height: 15px !important;
}

.statusContainer {
    padding: 5px;
    font-size: 9px;
    border-radius: 5px;
    text-align: center;
}

.icon {
    width: 16px;
    height: 16px;
    margin-top: 1px;
    object-fit: contain;
}

/* Voucher Card & Wallet Card */
.miscCard {
    display: flex;
    flex: 1;
    flex-direction: row;
    min-width: 280px;
    height: 80px;
    margin-inline: 5px;
    margin-bottom: 10px;
    padding: 15px 0px 0px 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.16);
}

.miscButton {
    background: linear-gradient(to bottom, #404c59, #2f3944);
    display: flex;
    height: 30px;
    margin-right: 20px;
    padding-inline: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    z-index: 1;
    cursor: pointer;
}

.deliveritContainer {
    display: flex;
    margin-top: -20px;
    margin-left: auto;
}

.deliveritLogo {
    width: 95px;
    height: 70px;
    object-fit: contain;
}

/* Summary Graph */
.summaryGraph {
    height: 250px;
    margin-block: 10px;
    margin-inline: 5px;
    padding: 5px 20px 10px 20px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

/* Recent Orders */
.recentOrders {
    height: 222px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-inline: 5px;
    padding: 15px 20px 10px 20px;
    text-align: left;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    overflow-y: scroll;
}

.locationRow {
    display: flex;
    margin-block: 10px;
}

.locationPinIcon {
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-top: 2px;
    margin-right: 10px;
}

.orderButton {
    background-color: #227c97;
    height: 21px;
    margin-left: auto;
    margin-inline: 5px;
    padding-inline: 8px;
    border-radius: 3px;
    cursor: pointer;
}

/* Responsive UI */
@media (max-width: 1280px) {
    .leftContainer {
        flex: 4.8;
    }
    
    .rightContainer {
        flex: 5.2;
    }
}

@media (max-width: 950px) {
    .mainContainer {
        padding-top: 40px;
    }
}

@media (max-width: 590px) {
    .mainContainer {
        padding: 40px 20px 0px 20px;
    }
}

@media (max-width: 560px) {
    .orderSummaryCard {
        min-width: 100px;
    }
}

@media (max-width: 550px) {
    .mainContainer {
        padding: 20px 20px 0px 20px;
    }
}