.mainContainer {
    padding: 30px 40px 0px 40px;
}

.hContainer {
    display: flex;
    flex-direction: row;
    min-height: 80vh;
    height: 100%;
    margin-top: 10px;
    padding: 5px;
    overflow-y: scroll;
}

.horizontalDivider {
    background-color: #e0e0e0;
    width: 100%;
    height: 1px;
    margin-block: 10px;
}

.title {
    margin: 0px;
    margin-bottom: 10px;
    color: black;
    font-size: 23px;
    font-weight: 500;
    text-align: left;
}

.blackText {
    margin: 0px;
    color: black;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    line-height: 22px;
}

.greyText {
    margin: 0px;
    color: #979797;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    line-height: 22px;
}

.whiteText {
    margin: 0px;
    color: white;
    font-size: 10px;
    font-weight: bold;
}

.tealText {
    margin: 0px;
    color: #227c97;
    font-size: 10px;
    font-weight: bold;
}

.icon {
    width: 15px;
    height: 15px;
    object-fit: contain;
    margin-right: 12px;
}

.addrTypeVContainer {
    flex: 2;
    height: 100%;
    margin-right: 15px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.addrTypeHContainer {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    margin-inline: 5px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.tabRow {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding-block: 15px;
    padding-inline: 20px;
    cursor: pointer;
}

.savedAddrContainer {
    flex: 8;
    margin-bottom: 20px;
    padding: 10px 25px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.locationRow {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-top: 10px;
    padding-block: 5px;
}

.buttonsContainer {
    display: flex;
    margin-left: auto;
    flex-direction: row;
    align-items: center;
}

.whiteButton {
    display: flex;
    align-items: center;
    margin-inline: 4px;
    padding: 6px 8px;
    justify-content: center;
    border: 1px solid #227c97;
    border-radius: 5px;
    cursor: pointer;
}

.tealButton {
    background-color: #227c97;
    display: flex;
    align-items: center;
    margin-inline: 4px;
    padding: 6px 15px;
    justify-content: center;
    border: 1px solid #227c97;
    border-radius: 5px;
    cursor: pointer;
}

/* Modals */
.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    cursor: pointer;
    display: block;
}

.deleteAddressModal {
    position: fixed;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    height: 130px;
    width: 300px;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    z-index: 101;
}

.createAddressModal {
    position: fixed;
    background-image: linear-gradient(white, #e1faff, #e1faff);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 370px;
    width: 450px;
    z-index: 101;
    padding: 20px;
    border-radius: 10px;
}

.bottomContainer {
    background-color: white;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-block: 15px;
    align-items: center;
    border-top: 1px solid #e5e2e2;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.inputContainer {
    min-height: 295px;
    overflow-y: scroll;
}

.inputRow {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 20px;
}

.inputArea {
    margin-inline: 5px !important;
}

.inputLabel {
    margin-top: -6px;
    margin-left: 10.5px;
    color: #979797;
    font-size: 12px !important;
    z-index: 1;
}

.inputText {
    background-color: white;
    font-size: 12px !important;
}

.contactNumberInputLabel {
    background-image: linear-gradient(#eefcff, white);
    margin-top: -6px;
    margin-left: 10.5px;
    padding-inline: 1.5px;
    color: #979797;
    font-size: 12px !important;
    border-radius: 5px;
    z-index: 10;
    transform: translate(0, 1.5px) scale(0.75) !important;
    transform-origin: top left !important;
}

/* Responsive UI */
@media (max-width: 580px) {
    .createAddressModal {
        width: 400px;
    }
}

@media (max-width: 520px) {
    .createAddressModal {
        width: 350px;
    }
}

@media (max-width: 480px) {
    .createAddressModal {
        width: 320px;
    }

    .inputContainer {
        height: 295px;
        margin-top: 12px;
    }

    .inputRow {
        flex-wrap: wrap;
        margin-block: 0px;
    }

    .inputArea {
        margin-block: 8px !important;
    }
}

@media (max-width: 420px) {
    .deleteAddressModal {
        width: 250px;
    }

    .createAddressModal {
        width: 280px;
    }
}

@media (max-width: 380px) {
    .deleteAddressModal {
        width: 220px;
    }

    .createAddressModal {
        width: 250px;
    }
}

@media (max-width: 340px) {
    .createAddressModal {
        width: 220px;
    }
}