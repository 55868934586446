.container {
    background-color: white;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
}

.gradientButton {
    background: linear-gradient(to right, #6AC4C5, #387596);
    border: 0;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    color: #fff !important;
    text-transform: none !important;
    height: 40px;
    padding: 0 30px;
    width: 150px;
}

.modal {
    display: 'flex';
    padding: 10px;
    align-items: 'center';
    justify-content: 'center';
}

.modalContainer {
    width: 80%;
    max-width: 800px;
    max-height: 80%;
    overflow: scroll;
    padding: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modalContainer:focus {
    outline: 0;
}

.closeButton {
    float: right;
    cursor: pointer;
}

.errorMessage {
    color: red;
    font-size: 12px;
}

.textInputContainer {
    margin-bottom: 12px;
    width: 100%;
}

.textInput {
    border: none;
    width: calc(100% - 20px);
    height: 40px;
    padding: 0 10px;
    border-radius: 3px;
    background: transparent;
}
  
.textInput:focus {
    outline: none !important;
    border: 2px solid #387596;
    box-shadow: 1px 2px 5px #719ECE;
}

.textInputLabel {
    margin: 10px 0;
}

.selectInput {
    border: none;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border-radius: 3px;
    background: transparent;
}
  
.selectInput:focus {
    outline: none !important;
    border: 2px solid #387596;
    box-shadow: 1px 2px 5px #719ECE;
}

.submitButtonContainer {
    margin-top: 30px;
    text-align: right;
}

.hstack {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
    flex: 1;
}

.vstack {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-direction: column;
    flex: 1;
}

.creditCardIcon {
    height: 22px;
    margin-left: 5px;
    margin-right: 20px;
}

.creditCardText {
    color: black;
    font-size: 14px;
}

.creditCardExpText {
    font-size: 12px;
    color: #929292;
}